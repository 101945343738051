import React, { useState } from 'react';
import { graphql, Link, navigate } from "gatsby";
import { Grid } from '@material-ui/core';
import Layout from '../components/layout';
import TagIcon from '../assets/products/Icon.svg';
import CatIcon from '../assets/products/cat-icon.svg';
import { useTheme } from '@material-ui/core/styles';
import he from 'he'
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {
  withStyles
} from '@material-ui/core/styles';
import Logo from '../assets/LOGO.svg'
import { useStyles } from '../theme/theme';
import CloseIcon from '@material-ui/icons/Close';
import TextField from '@material-ui/core/TextField';
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {
  Navigation, Thumbs
} from 'swiper/core';
// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/components/navigation/navigation.min.css"
import "swiper/components/thumbs/thumbs.min.css"
import "./single-product.css"
import ContactDialog from '../components/Modal';
import Dialog from '@material-ui/core/Dialog';
import Thankyou from '../components/Thankyou';
import MapBox from "../assets/map_box.png"
import ContactUs from "../components/Contactus"
import MapImage from "../assets/map.png"

SwiperCore.use([Navigation, Thumbs]);


const CssTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: '#3D55A4',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#3D55A4',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#E0E0E0',
      },
      '&:hover fieldset': {
        borderColor: '#E0E0E0',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#3D55A4',

      },
    },
  },
})(TextField);


const SingleProduct = ({ data }) => {
  const post = data.wpProduct;

  console.log(post)
  const daId = post.databaseId;
  console.log(daId)
  const [Open, setOpen] = React.useState(false);
  const [Open1, setOpen1] = React.useState(false);
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [Fname, setFname] = useState('');
  const [Lname, setLname] = useState('');
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [country, setCountry] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [state, setState] = useState('');
  const [note, setNote] = useState('');

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  /* For Thankyou Modal */

  const handleClickOpen1 = () => {
    setOpen1(true);
  };

  const handleClose1 = () => {
    setOpen1(false);
  };

  const asyncPostCall = async () => {
    try {
      const response = await fetch('https://of.designer-dev.com/wp-json/wc/v3/orders?consumer_key=ck_555edaa14f9f2e2dbc69c27e4b549bce31cd3d93&consumer_secret=cs_6f2102593884b839e21e0234d2a70b9810206bce', {
        method: 'POST',

        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          // your expected POST request payload goes here
          payment_method: "cod",
          payment_method_title: "Cash On Delivery",
          set_paid: false,
          status: "processing",
          customer_note: note,
          billing: {
            first_name: Fname,
            last_name: Lname,
            address_1: address,
            address_2: "",
            city: city,
            state: state,
            postcode: "94103",
            country: country,
            email: email,
            phone: phone
          },
          shipping: {
            first_name: Fname,
            last_name: Lname,
            address_1: address,
            address_2: "",
            city: city,
            state: state,
            postcode: "94103",
            country: country
          },
          line_items: [
            {
              product_id: daId,
              sku: "",
              quantity: 2
            }
          ],
          shipping_lines: [
            {
              method_id: "flat_rate",
              method_title: "Flat Rate",
              total: "12.00"
            }
          ]
        })
      });
      const data = await response.json();
      // enter you logic when the fetch is successful
      console.log(data);
      handleClickOpen1();
      handleClose();
      setTimeout(() => {
        handleClose1()
        navigate('/products')
      }, 10000);
    } catch (error) {
      // enter your logic for when there is an error (ex. error toast)

      console.log(error)
    }
  }


  const handleSubmit = (e) => {
    asyncPostCall()
    e.preventDefault();
    handleClose()
  }




  const description = () => {
    let htmlString = post?.description

    if (htmlString) {
      let stripedHtml = htmlString.replace(/<[^>]+>/g, '');
      let decodedStripedHtml = he.decode(stripedHtml);
      console.log(decodedStripedHtml)
    }
  }



  const classes = useStyles();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("md"))
  const isXtraSmall = useMediaQuery(theme.breakpoints.down("sm"))
  const isMed = useMediaQuery(theme.breakpoints.down(1280))
  const is1300 = useMediaQuery(theme.breakpoints.down(1300));
  const isTab = useMediaQuery(theme.breakpoints.down(1024));
  const isMob = useMediaQuery(theme.breakpoints.down(768));
  const fullScreen = useMediaQuery(theme.breakpoints.down(400));
  var tags = post?.productTags?.nodes
  var categories = post?.productCategories.nodes
  var cleanTags = Array.prototype.map.call(tags, function (item) { return item.name; }).join(", ");
  var cleanCategories = Array.prototype.map.call(categories, function (item) { return item.name; }).join(", ");


  return (
    <Layout>
      <Grid container spacing={is1300 ? 4 : 8} direction="row" justify="space-evenly" alignItems="center" style={{ padding: `${is1300 ? "10px" : "50px"}` }}>
        <Grid item sm={12} md={7} lg={6} xl={6} style={{ paddingTop: "50px" }}>
          {/* <ImageGallery items={Images} thumbnailPosition="left" showFullscreenButton={false} disableSwipe={true} showNav={false} showPlayButton={false}/> */}
          <div style={{ display: "flex", flexDirection: `${isMob ? "column-reverse" : "row"}`, justifyContent: `${isTab ? "center" : "space-between"}`, alignItems: "center" }}>
            <Swiper onSwiper={setThumbsSwiper} spaceBetween={isTab ? 10 : 50} slidesPerView={3} watchSlidesVisibility={true} watchSlidesProgress={true} direction={isMob ? "horizontal" : "vertical"} slideToClickedSlide="true" className="mySwiperThumb">
              <SwiperSlide className="thumbnail"><img src={post.image.sourceUrl} /></SwiperSlide>
              {post.galleryImages.nodes.map((image, index) => (
                <SwiperSlide className="thumbnail" key={index}><img src={image.sourceUrl} /></SwiperSlide>
              ))}

            </Swiper>
            <Swiper style={{ '--swiper-navigation-color': '#fff', '--swiper-pagination-color': '#fff' }} loop={true} spaceBetween={0} navigation={true} thumbs={{ swiper: thumbsSwiper }} className="productSwiper">
              <SwiperSlide className="original-slide" ><img src={post.image.sourceUrl} /></SwiperSlide>
              {post.galleryImages.nodes.map((image, index) => (
                <SwiperSlide className="original-slide" key={index}><img src={image.sourceUrl} /></SwiperSlide>
              ))}
            </Swiper>

          </div>
        </Grid>
        <Grid item sm={10} md={5} lg={6} xl={6}>
          <h3 className="product-page-title">{post.name}</h3>
          <p className="product-details"><span>Size:</span> {post.productCustomField?.size}</p>
          <p className="product-details"><span>Scientific Name: </span>{post.productCustomField?.scientificName}</p>
          <p className="product-details"><span>Country of origin: </span>{post.productCustomField?.countryOfOrigin}</p>
          <div style={{ marginBottom: "20px" }}><div className="product-details-description"><span>Description: </span><p dangerouslySetInnerHTML={{ __html: post?.description }} /> </div></div>
          <ContactDialog isOpen={Open} handleClose={handleClose} />
          <div className="product-extra"><img src={CatIcon} /><p className="product-details">Categories: {cleanCategories}</p></div>
          <div className="product-extra"><img src={TagIcon} /><p className="product-details">Tags: {cleanTags}</p></div>
          <button className="contact-sales-btn" onClick={handleClickOpen}>Contact Sales</button>
        </Grid>
      </Grid>
      <Dialog

        open={Open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <div className={classes.modal_header}>
          <div className={classes.modal_header_logo}>

            <img src={Logo} alt="ocean fishery" />
          </div>
          <div className={classes.cross}>
            <CloseIcon onClick={handleClose} style={{ cursor: "pointer", fontSize: "28px" }} />
          </div>
        </div>
        <form onSubmit={handleSubmit}>
          {/* <input type="text" name="Frist Name" placeholder="FirstName" value={Fname} onChange={(e) => setFname(e.target.value)}/>
              <input type="text" name="LastName" placeholder="LastName" value={Lname} onChange={(e) => setLname(e.target.value)}/>
              <input type="text" name="Address" placeholder="Address" value={address} onChange={(e) => setAddress(e.target.value)}/>
              <input type="text" name="City" placeholder="City" value={city} onChange={(e) => setCity(e.target.value)}/>
              <input type="email" name="Email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)}/>
              <input type="number" name="Phone" placeholder="Phone" value={phone} onChange={(e) => setPhone(e.target.value)}/>
              <input type="text" name="State" placeholder="State" value={state} onChange={(e) => setState(e.target.value)}/>
              <input type="text" name="Country" placeholder="Country" value={country} onChange={(e) => setCountry(e.target.value)}/> */}
          <Grid container spacing={5} justify="center" direction="row" style={{ textAlign: "center", margin: "0px 0px 20px 0px" }}>
            <Grid item xs={12} sm={6}>
              <CssTextField
                id="outlined-basic"
                label="First Name"
                variant="outlined"
                name="first-name"
                type="text"
                className={classes.first_name}
                style={{ backgroundColor: "#fff" }}
                value={Fname} onChange={(e) => setFname(e.target.value)}
                required
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <CssTextField
                id="outlined-basic"
                label="Last Name"
                variant="outlined"
                type="text"
                required
                className={classes.last_name}
                value={Lname} onChange={(e) => setLname(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <CssTextField
                id="outlined-basic"
                label="Email Address"
                variant="outlined"
                type="email"
                required
                className={classes.email_address}
                value={email} onChange={(e) => setEmail(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <CssTextField

                id="outlined-basic"
                label="Message"
                variant="outlined"
                multiline
                rows={6}
                type="text"
                className={classes.message_box}
                value={note} onChange={(e) => setNote(e.target.value)}
              />
            </Grid>
            <Grid
              item
              xs={12}
              style={{ textAlign: "center" }}
            >
              <button className={classes.btn_variant_2}>Send Message</button>
            </Grid>
          </Grid>
        </form>

      </Dialog>
      <Thankyou open1={Open1} handleClose1={handleClose1} />
      <Grid container className={classes.related_product} style={{ marginTop: "50px" }}>
        <Grid xs={12} className={classes.related_product_content}>
          <h3>Related products</h3>
        </Grid>
      </Grid>
      <Grid container spacing={4} className={classes.related_product_body} direction="row" justify="center" alignItems="flex-start" style={{ paddingTop: "50px", marginBottom: "50px", paddingBottom: "50px" }}>
        {post.related.nodes?.map((related_product, index) => {

          return (
            <Grid item xs={10} sm={5} md={4} lg={3} xl={2} key={index} className={classes.feature_product}>
              <Link to={`/product/${related_product.slug}`}>
                <div className={classes.feature_image}>
                  <img src={related_product.image?.sourceUrl} />
                </div>
                <p className={classes.feature_title}>{related_product.name}</p>
                <p className={classes.feature_cat}>{related_product.productCategories.nodes?.map(rel_cat => rel_cat.name)}</p>
              </Link>
            </Grid>
          )
        })}
      </Grid>

      <Grid
        container
        spacing={0}
        direction="row"
        justify={isSmall ? "center" : "start"}
        alignItems="center"
        className={classes.contact_section}

        style={{ paddingTop: "50px" }}
      >
        <Grid
          item
          xs={12}
          sm={10}
          md={6}
          lg={6}
          xl={6}
          className={classes.map_image}
        >
          {/* <img
            src={MapBox}
            alt="ocean fishry address"
            className={classes.map_box}
          /> */}
          <img src={MapImage} alt="ocean fishry map" className={classes.map} />
        </Grid>
        <Grid
          item
          xs={12}
          sm={10}
          md={6}
          lg={5}
          xl={5}
          className={classes.contact_form}

        >

          <ContactUs isSmall={isSmall} />
        </Grid>
        <div class="ocean">
          <div class="wave wave1"></div>
          <div class="wave wave2"></div>
          <div class="wave wave3"></div>
          <div class="wave wave4"></div>
        </div>
      </Grid>

    </Layout>
  )
}

export default SingleProduct;

export const query = graphql`
query($slug: String!) {
  wpProduct(slug: {eq: $slug}) {
    galleryImages {
      nodes {
        sourceUrl
        sizes
      }
    }
    databaseId
    sku
    slug
    productCategories {
      nodes {
        name
      }
    }
    productCustomField {
      countryOfOrigin
      scientificName
      size
    }
    productTags {
      nodes {
        name
      }
    }
    wpParent {
      name
    }
    id
    description
    name
    image {
      sourceUrl
    }
    related {
      nodes {
        name
        productCategories {
          nodes {
            name
          }
        }
        image {
          sourceUrl
        }
        slug
      }
    }
  }
}
`